import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"

import marked from "marked"

class BlogTemplate extends React.Component {
	render() {
		const data = this.props.data.sanityPost
		const { previous, next } = this.props.pageContext
		return (
			<Layout location={this.props.location}>
				<section className="page">
					<div className="container text-white">
						<div className="content bg-black p-5">
							<div className="section-content">
								<div className="row">
									<div className="col-md-12">
										<div className="text-center">
											<img
												style={{ maxWidth: `100%`, height: `auto` }}
												src={data.mainImage.asset.fluid.src}
												alt={data.title}
											/>
										</div>
										<h1 className="mt-5">{data.title}</h1>
										<small>{data.publishedAt}</small>
										<div
											className="mt-5"
											dangerouslySetInnerHTML={{ __html: marked(data.body) }}
										/>
									</div>
								</div>
							</div>
							<div className="blog-nav mt-5 pt-5">
								<div className="container">
									<div className="row">
										<div className="col-md-6 text-md-left">
											{previous ? (
												<div className="prev-flex">
													<div>
														<Link
															to={`${previous.slug.current}`}
															className="float-left prev-icon"
														>
															<i className="fa fa-angle-left" />
														</Link>
													</div>
													<div>
														<span className="font-weight-bold text-uppercase text-muted d-block small">
															Previous Post
														</span>
														<Link to={`${previous.slug.current}`}>
															{previous.title}
														</Link>
													</div>
												</div>
											) : null}
										</div>
										<div className="col-md-6 text-md-right">
											{next ? (
												<div className="next-flex">
													<div>
														<span className="font-weight-bold text-uppercase text-muted d-block small">
															Next Post
														</span>
														<Link to={`${next.slug.current}`}>
															{next.title}
														</Link>
													</div>
													<div>
														<Link
															to={`${next.slug.current}`}
															className="float-right next-icon"
														>
															<i className="fa fa-angle-right" />
														</Link>
													</div>
												</div>
											) : null}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Layout>
		)
	}
}

export default BlogTemplate

export const blogQuery = graphql`
	query {
		sanityPost {
			title
			mainImage {
				asset {
					fluid {
						src
					}
				}
			}
			publishedAt(formatString: "MMMM, DD YYYY")
			body
		}
	}
`
